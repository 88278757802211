import { FC, PropsWithChildren } from 'react'
import { Text } from '@blueprintjs/core'
import { ArrowRight } from '@blueprintjs/icons'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import { motion } from 'framer-motion'

type TProps = {
  onClick: () => void
  noBorder?: boolean
} & PropsWithChildren

const AnimatedButton: FC<TProps> = ({ onClick, noBorder = false, children }) => {
  return (
    <button
      className={cn(
        ctl(`
          group relative flex h-12 cursor-pointer
          items-center rounded-sm p-0
          uppercase text-text-white focus:outline-none`),
        noBorder ? 'border-0 bg-surface-primary' : 'border-1 border-solid border-surface-white bg-transparent',
      )}
      type="button"
      onClick={onClick}
    >
      <div className="absolute flex h-full w-full items-center justify-end p-1 group-hover:justify-end">
        <motion.div
          className="relative right-0 flex h-full w-10 rounded-sm bg-surface-tertiary transition-all duration-400 group-hover:right-10 group-hover:w-[calc(100%-2.5rem)]"
          transition={{ duration: 0.7 }}
        />
      </div>
      <div className="relative flex w-full items-center">
        <Text tagName="span" className="font-primary-bold---12pt flex whitespace-nowrap px-4 md:px-12">
          {children}
        </Text>
        <div className="flex px-3">
          <ArrowRight size={24} />
        </div>
      </div>
    </button>
  )
}

export { AnimatedButton }
