import i18n from 'client/locales/i18n'
import * as yup from 'yup'

import { ClientLocaleNS } from './locales'

export const ERROR_MESSAGES = {
  PRESENCE: {
    DEFAULT: i18n.t('validation.presence', { ns: ClientLocaleNS.Common }),
  },
  INVALID_VALUE: i18n.t('validation.value', { ns: ClientLocaleNS.Common }),
  EMAIL: i18n.t('validation.email', { ns: ClientLocaleNS.Common }),
}

export const CLAIM_FORM_SCHEMA = yup.object().shape({
  first_name: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT),
  last_name: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT),
  email: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT).email(ERROR_MESSAGES.EMAIL),
  phone_number: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT),
  company_name: yup.string(),
  company_website: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT),
  address: yup.string(),
  annual_income: yup.string(),
})

export const POST_FORM_SCHEMA = yup.object().shape({
  title: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT),
})

export const FAQ_FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(ERROR_MESSAGES.PRESENCE.DEFAULT),
})

export const PHONE_MASK = '+_______________'
export const PHONE_MASK_REPLACEMENT = { _: /\d/ }
